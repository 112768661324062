/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import './AllOWDs.scss';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import req from 'utils/request';
import { TRANSLATIONS } from 'types/enums';
// import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import {
  selectSelectedLocationsSlug,
  selectLocationsToAccess,
  selectSelectedBusinessUnit,
} from 'store/selectors/globalFilters.selectors';
// import ChartDropdown from 'components/Dropdown/ChartDropdown';
// import { useCubeQuery } from '@cubejs-client/react';
import noData from 'assets/icons/no-data.svg';
import { ClientType, useGetAllOwdChecklistsLazyQuery } from 'generated/graphql';
import moment from 'moment';
import BasicButton from 'components/Button/BasicButton';
// import DatePicker from 'react-datepicker';
import LoadingSpinner from 'components/LoadingSpinner';
import ImageLinks from 'utils/ImageLinks';
import Toast from 'components/Toast';
import InputWithLabel from 'components/Input/InputWithLabel';
import { useDebounce } from 'features/internal-users/user/UsersIndex/UsersIndex';
import OWDFileDropdown from './OWDFileDropdown';
import request from '../../utils/request';
import userSearch from '../../assets/icons/input-search.svg';

const { file, info, loadingIcon, EmptyImg, upload } = ImageLinks;

interface OWDEmptyProps {
  label?: string;
  className?: string;
}
export enum OWD_FILE_STATUS_TYPE {
  LOADING = 'loading',
  ARCHIVE = 'archive',
}
export enum OWD_FILE_UPLOAD_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  LOAD_FILE = 'Load File',
  IDLE = 'IDLE',
  FAILED = 'FAILED',
}

const validFileExtensions = ['.csv', '.xlsx'];
const Validate = (files: any) => {
  for (let i = 0; i < files.length; i++) {
    const oInput = files[i];

    const sFileName = oInput.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j++) {
        const sCurExtension = validFileExtensions[j];
        console.log({ sCurExtension, sFileName });
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(`Sorry, ${sFileName} is invalid, allowed extensions are: ${validFileExtensions.join(', ')}`);
        return false;
      }
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HiddenInput = ({ onChange = (e: any) => { } }) => {
  const { t } = useTranslation();
  const lineRefs = React.useRef<any>();

  const handleClick = () => {
    lineRefs.current.click();
  };

  return (
    <div className="upload-icon" role="button" onClick={handleClick}>
      <input type="file" style={{ display: 'none' }} ref={lineRefs} onChange={onChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
      <img src={upload} alt="upload" />
      <p className="upload-icon__tilte">{t(TRANSLATIONS.FILE_UPLOAD)}</p>
    </div>
  );
};

const OWDContainer = () => {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [owds, setOWDS] = useState<any>([]);
  const [toastList, setToastList] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const userVersion = 'WEB';
  // const { resultSet: pillars } = useCubeQuery({
  //   dimensions: ['PillarsList.id', 'PillarsList.name'],
  // });
  // const [pillarDropdown, setPillarDropdown] = useState(false);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const loggedInUser = useSelector(selectUser);

  const inputRef = useRef<HTMLInputElement>(null);

  const { email: loggedInUserEmail } = loggedInUser.toJS();

  // const pillarList = useMemo(() => {
  //   return [
  //     { slug: '', label: 'All Pillars' },
  //     ...get(pillars, 'loadResponses[0].data', []).map((pillarItem: any) => ({
  //       slug: pillarItem['PillarsList.id'],
  //       label: capitalizeFirst(pillarItem['PillarsList.name']),
  //     })),
  //   ];
  // }, [pillars]);

  // const [selectedPillars, setSelectedPillars] = useState({ slug: '', label: 'All Pillars' });
  const { location } = useSelector(selectSelectedLocationsSlug);
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const businessUnit = useSelector(selectSelectedBusinessUnit);

  const debouncedUsersReq = useDebounce(search, 500);

  const [
    getAllOwds,
    { loading: isLoadingAllOWD, data: getAllOWDChecklists },
  ] = useGetAllOwdChecklistsLazyQuery();

  const updateToastList = (list: any[]) => {
    setToastList(list);
  };

  const handleSearch = () => {
    getAllOwds({
      variables: {
        locationSlug: location,
        source: ClientType.App,
        limit,
        offset,
        userVersion,
        userSearch: search,
      },
      fetchPolicy: 'no-cache',
    });
  };

  useEffect(() => {
    if (location) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line
  }, [debouncedUsersReq]);

  useEffect(() => {
    setOffset((page - 1) * limit);
    getAllOwds({
      variables: {
        locationSlug: location,
        source: ClientType.App,
        limit,
        offset: (page - 1) * limit,
        userVersion,
        userSearch: search,
      },
      fetchPolicy: 'no-cache',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedUsersReq]);

  useEffect(() => {
    const formatted = (getAllOWDChecklists?.getAllOWDChecklists || [])?.map((owd: any) => ({
      checklistAvailableId: owd.id,
      version: Number(owd?.content?.version ?? 1),
      createdAt: owd.createdAt,
      updatedAt: owd.updatedAt,
      pillar: owd?.content?.pillar,
      owd: `OWD-${owd?.name}`,
      last_load: moment(owd?.updatedAt || owd?.createdAt).format('YYYY-MM-DD HH:mm A'),
      template: owd?.content?.templateLink,
      archive:
        owd?.content?.uploadStatus === OWD_FILE_UPLOAD_STATUS.IN_PROGRESS
          ? OWD_FILE_STATUS_TYPE.LOADING
          : owd?.content?.uploadStatus
            === OWD_FILE_UPLOAD_STATUS.IDLE && owd?.content?.archiveLink
            ? owd?.content?.archiveLink
            : OWD_FILE_UPLOAD_STATUS.LOAD_FILE,
    }));
    setOWDS(formatted);
    // eslint-disable-next-line
  }, [JSON.stringify(getAllOWDChecklists?.getAllOWDChecklists)]);

  const archive = (value: any) => {
    if (value === OWD_FILE_STATUS_TYPE.LOADING) {
      return OWD_FILE_STATUS_TYPE.LOADING;
    }
    if (value === OWD_FILE_UPLOAD_STATUS.LOAD_FILE) {
      return OWD_FILE_STATUS_TYPE.ARCHIVE;
    }
    return '';
  };

  // const handlePillarChange = ({ key }: { key: any }) => {
  //   setSelectedPillars(
  //     pillarList.find((item: any) => item.slug.toString() === key.toString()) || pillarList[0],
  //   );
  //   setPillarDropdown(false);
  // };
  const handleUpload = useCallback(
    async (e: any, row: any) => {
      const { files } = e.target;
      const toasts = [...toastList];
      if (files?.length && Validate(files)) {
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
        };
        const version = row?.original?.version ?? 1;
        const formData = new FormData();
        formData.append('date', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        formData.append('locationId', locationsToAccess[0]);
        formData.append('checklistAvailableId', row?.original?.checklistAvailableId);
        formData.append('file', files[0]);
        formData.append('templateLink', row?.original?.template);
        formData.append('version', row?.original?.version + 1);

        const reUploadObj = {
          e: {
            target: {
              files: [files[0]],
            },
          },
          row: {
            original: {
              checklistAvailableId: row?.original?.checklistAvailableId,
              template: row?.original?.template,
              version,
              pillar: row?.original?.pillar,
              owd: row.original.owd,
            },
          },
        };
        try {
          const owdList = JSON.parse(JSON.stringify(owds));
          const updatedOWD = owdList.find(
            (owd: any) => owd.checklistAvailableId === row?.original?.checklistAvailableId,
          );
          updatedOWD.archive = OWD_FILE_STATUS_TYPE.LOADING;
          setOWDS(owdList);
          const response = await request.post('/api/v1/owd/upload-excel', formData, config);
          const owdListAfterUpload = JSON.parse(JSON.stringify(owds));
          const updatedOWDAfterUpload = owdListAfterUpload.find(
            (owd: any) => owd.checklistAvailableId === row?.original?.checklistAvailableId,
          );
          if (response.uploadStatus === OWD_FILE_UPLOAD_STATUS.IN_PROGRESS) {
            updatedOWDAfterUpload.archive = response.s3Url;
          }
          const updatedOWDIndex = owds.findIndex(
            (owd: any) => owd.checklistAvailableId === row?.original?.checklistAvailableId,
          );
          if (response.uploadStatus === OWD_FILE_UPLOAD_STATUS.FAILED) {
            toasts.push({
              title: t(TRANSLATIONS.AN_ERROR_HAS_OCCURRED),
              description: t(TRANSLATIONS.RETRY_AGAIN),
              reUploadObj,
            });
          } else {
            toasts.push({
              title: `Se cargó correctamente el archivo ${row.original.owd}_${row?.original?.pillar}.`,
              description: 'OK',
            });
          }
          owdListAfterUpload[updatedOWDIndex] = { ...updatedOWDAfterUpload };
          setOWDS(owdListAfterUpload);
          setToastList(toasts);
        } catch (err) {
          toasts.push({
            title: t(TRANSLATIONS.AN_ERROR_HAS_OCCURRED),
            description: t(TRANSLATIONS.RETRY_AGAIN),
            reUploadObj,
          });
          setToastList(toasts);
          console.log({ err });
        }
      }
    },
    // eslint-disable-next-line
    [locationsToAccess, owds, toastList],
  );

  const columns = useMemo(() => {
    return [
      {
        Header: () => <p className="headerTitle">OWD</p>,
        accessor: 'owd',
        Cell: (row: any) => {
          return <p className="owd_name">{String(row.value ? row.value : 'N/A')}</p>;
        },
        minWidth: 20,
      },
      {
        Header: () => <p className="headerTitle">{t(TRANSLATIONS.LAST_LOAD)}</p>,
        accessor: 'last_load',
        Cell: (row: any) => {
          return <p className="owd_last_upload">{String(row.value ?? 'N/A')}</p>;
        },
        minWidth: 20,
      },
      {
        Header: <p className="headerTitle">{t(TRANSLATIONS.TEMPLATE)}</p>,
        accessor: 'template',
        minWidth: 20,
        Cell: (row: any) => {
          return (
            <div className="template">
              {' '}
              <a href={row.value} target="_blank" rel="noreferrer">
                {' '}
                <p className="template__download">{t(TRANSLATIONS.DOWNLOAD_TEMPLATE)}{' '}</p>
              </a>
            </div>
          );
        },
      },
      {
        Header: <p className="headerTitle">{t(TRANSLATIONS.ARCHIVO)}</p>,
        accessor: 'archive',
        minWidth: 40,
        Cell: (row: any) => {
          const archiveFileName = `${row.original.owd}_${row?.original?.pillar}_${moment(
            row?.original?.updatedAt || row?.original?.createdAt,
          ).format('DD_MM_YYYY')}`;
          return (
            <div className={`${archive(row.value)} action`}>
              {row.value === OWD_FILE_UPLOAD_STATUS.LOAD_FILE && (
                <HiddenInput
                  onChange={async (e: any) => {
                    handleUpload(e, row);
                  }}
                />
              )}
              {row.value === OWD_FILE_STATUS_TYPE.LOADING && <img src={loadingIcon} alt="loading-icon" width="32" height="32" />}
              {row.value !== OWD_FILE_UPLOAD_STATUS.LOAD_FILE
                && row.value !== OWD_FILE_STATUS_TYPE.LOADING && (
                  <div className="flex">
                    <div className="archive-content-container">
                      <img className="fileImage" src={file} alt="file" />
                      <div className="fileName-container">
                        <p className="fileName">{archiveFileName}</p>
                      </div>
                    </div>
                    <OWDFileDropdown
                      value={row?.original}
                      archiveOnChange={(e: any) => {
                        handleUpload(e, row);
                      }}
                    />
                  </div>
              )}
            </div>
          );
        },
      },
    ] as any;
  }, [t, handleUpload]);

  const OWDEmptyPage = () => {
    return (
      <div className="no_location">
        {' '}
        <img src={EmptyImg} alt="Select Location" />
      </div>
    );
  };

  const OWDEmpty = ({ label, className }: OWDEmptyProps) => {
    return (
      <div className={`empty-state ${className}`}>
        <img src={noData} alt="No data" />
        <p>{label}</p>
        {!label && (
          <>
            <span>{t(TRANSLATIONS.NO_DATA)}</span>
            <p>{t(TRANSLATIONS.NO_DATA_FOUND_SO_FAR)}</p>
          </>
        )}
      </div>
    );
  };

  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    const toasts = [...toastList];
    if (files?.length && Validate(files)) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const date = new Date();
      const data = new FormData();

      data.set('file', files[0]);
      data.set('locationId', locationsToAccess);
      data.set('bu', businessUnit.name);
      data.set('date', date.toLocaleString());

      let prioritizeLocationOverBU: any = false;
      if (location) {
        prioritizeLocationOverBU = true;
      }
      data.set('prioritizeLocationOverBU', prioritizeLocationOverBU);

      try {
        setLoading(true);
        await req.post('/api/v1/owd/upload-excel', data, config);
        setLoading(false);
        toasts.push({
          title: t(TRANSLATIONS.YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES),
          description: 'OK',
        });
        setToastList(toasts);
      } catch (err) {
        setLoading(false);
        toasts.push({
          title: t(TRANSLATIONS.AN_ERROR_HAS_OCCURRED),
          description: 'OK',
        });
        setToastList(toasts);
      } finally {
        if (fileRef.current !== null) {
          setLoading(false);
          fileRef.current.value = '';
        }
      }
    }
  };

  const handleInputSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <section className="OWD_container">
      <div className="owd_header">
        <p className="title">OWDs</p>
        <div className="upload_container">
          {businessUnit.name ? (
            <div className={loggedInUserEmail.includes('arvolution') ? 'upload_button' : 'hidden'}>
              <BasicButton
                text="Upload"
                className="upload_button_filled"
                onClick={handleUploadButton}
                disabled={loading}
              />
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                onChange={handleAttachmentsUpload}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          ) : null}
          <a
            href="https://arvolution.zendesk.com/hc/es-419/articles/22550378124187--C%C3%B3mo-utilizar-las-plantillas-de-OWDs"
            rel="noreferrer"
            target="_blank"
          >
            <div className="tutorial">
              {' '}
              <img src={info} alt="tutorial" />
              <p className="tutorial__title">Tutorial{' '}</p>
            </div>
          </a>
        </div>
      </div>
      {location && !isLoadingAllOWD && <p className="OWD_container__note">{t(TRANSLATIONS.NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE)}</p>}
      {location && (
        <div>
          <InputWithLabel
            inputRef={inputRef}
            inputImg={userSearch}
            onChange={handleInputSearch}
            placeholder={t(TRANSLATIONS.SEARCH_FOR_OWD_HERE)}
            value={search}
            labelClassName="search-header"
            inputClassName="search-input owd-search"
          />
        </div>
      )}
      <div className="body">
        {isLoadingAllOWD && <LoadingSpinner />}
        {location && !isLoadingAllOWD ? (
          <>
            <ReactTable
              minRows={0}
              data={owds}
              columns={columns}
              showPagination={false}
              className="-striped max-height"
              NoDataComponent={OWDEmpty}
            />
            <div className="table-footer">
              <Button
                variant="contained"
                onClick={() => {
                  setPage(p => p - 1);
                }}
                disabled={page < 2}
              >
                <p className="table-footer__previousTitle">Previous</p>
              </Button>
              <div className="page">
                <div>
                  {' '}
                  <p className="table-footer__pageNumber">Page: <span>{page}</span></p>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  setPage(p => p + 1);
                }}
                disabled={!(owds.length === limit)}
              >
                <p className="table-footer__nextTitle">Next</p>
              </Button>
            </div>
            {/* <div className="filters">
              <div className="column">
                <h5>Start Date</h5>
                <DatePicker selected={startDate} onChange={setStartDate} maxDate={new Date()} />
              </div>
              <div className="column">
                <h5>End Date</h5>
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  minDate={startDate}
                  maxDate={new Date()}
                />
              </div>
              <div className="column">
                <h5>Pillar</h5>
                <ChartDropdown
                  onChange={handlePillarChange}
                  selectedDropDownItem={selectedPillars}
                  dropDownItems={pillarList}
                  dropDownOpen={pillarDropdown}
                  setDropDownOpen={setPillarDropdown}
                  labelClassName="label-default"
                />
              </div>
            </div> */}
          </>
        ) : (
          !isLoadingAllOWD && <OWDEmptyPage />
        )}
      </div>
      <Toast
        toastList={toastList}
        position="bottom-right"
        updateToastList={(list: any[]) => updateToastList(list)}
        reUploadCallback={(event: any, rowData: any) => handleUpload(event, rowData)}
      />
    </section>
  );
};

export default OWDContainer;
