import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Divider, InputAdornment, TextField } from '@mui/material';
import PinIcon from 'assets/icons/pin.svg';
import UserIcon from 'assets/icons/user.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LocationMarkerIcon from 'assets/icons/location-marker.svg';
import MazFlag from 'assets/icons/maz-icon.svg';
import GraduateIcon from 'assets/icons/graduate.svg';
import { useGetAllLocationsUserPositionsQuery, useGetDepartmentAreaSubAreaLazyQuery } from 'generated/graphql';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import homeIcon from 'assets/icons/home-black.svg';
import calendarIcon from 'assets/icons/calendar.png';
import i18next from 'i18next';
import Autocomplete from '@mui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { userIsAssigner, userIsExecutor } from 'utils/shared/canUserAccessFeature';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { UserManagementContext } from '../../context';

function DistributionCenterInfoCard(props: any) {
  const { label, img, value } = props;
  return (
    <div className="distribution-center-card">
      <span className="label">{label}</span>
      <div>
        {img && <img width="24px" src={img} alt={value} />}
        <span className="value">{value || 'Not Available'}</span>
      </div>
    </div>
  );
}

function InfoRow(props: any) {
  return (
    <div className="info-row-container">
      <div className="d-flex">
        <div className="info-row-icon">
          <img src={props.icon} width="100%" height="100%" alt="delete file" />
        </div>
        <span className="info-row-label">{props.label}</span>
      </div>
      <span className="info-row-value">{props.value}</span>
    </div>
  );
}

const General = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserManagementContext);
  const { originalUser, isEditing, editingUser, setEditingUser, setIsLoading } = userContext;

  const allLocationsPositions = useGetAllLocationsUserPositionsQuery();
  const originalUserInfo = originalUser?.get('general')?.toJS();
  const editingUserInfo = editingUser?.get('general')?.toJS();

  const [showPin, setShowPin] = useState(false);
  const [accessLocationId, setAccessLocationId] = useState(null);
  const [accessLocationType, setAccessLocationType] = useState(
    originalUserInfo?.distributionCenter?.type,
  );
  const [AccessLocationBuId, setAccessLocationBuId] = useState(null);
  const [positionsSelectedBu, setPositionsSelectedBu] = useState();
  const [showDepAreaSubarea, setShowDepAreaSubarea] = useState(false);

  const [department, setDepartment] = useState<any>(null);
  const [area, setArea] = useState<any>(null);
  const [, setSubArea] = useState<any>(null);
  const [disabedFieldsState, setDisabedFieldsState] = useState<any>(
    { department: false, area: false, subArea: false },
  );

  const [callDepartmentAreaSubArea, { data: departmentAreaSubArea, loading }] = useGetDepartmentAreaSubAreaLazyQuery({ fetchPolicy: 'no-cache' });

  const getBuIdByLocationId = async (id: number) => {
    const locationSelected = await allLocationsPositions?.data
      ?.getAllLocationsUserPositions?.locations.filter((location: any) => {
        return +location.id === +id;
      });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    locationSelected && setAccessLocationBuId(locationSelected[0]?.buId);
  };

  const getLocationTypeByLocationId = (id: number) => {
    const locationSelected = allLocationsPositions?.data
      ?.getAllLocationsUserPositions?.locations.filter((location: any) => {
        return +location.id === +id;
      });

    return locationSelected[0]?.type;
  };

  const accessLocationIdTemp = useMemo(() => {
    return (!isEditing ? originalUserInfo : editingUserInfo)?.distributionCenter?.id;
  }, [originalUserInfo, editingUserInfo, isEditing]);

  const positionByBU = useMemo(() => {
    if (editingUserInfo?.position) {
      return editingUserInfo?.position;
    }
    return null;
  }, [editingUserInfo]);

  const disabedFields = useMemo(() => {
    const obj = {
      department: false,
      area: false,
      subArea: false,
    };

    if (!editingUserInfo?.distributionCenter?.id) {
      obj.department = true;
    }
    if (!editingUserInfo?.userWorkLocation?.workLocation) {
      obj.area = true;
    }
    if (!editingUserInfo?.userWorkLocation?.subLocation) {
      obj.subArea = true;
    }
    return obj;
  }, [editingUserInfo]);

  useEffect(() => {
    if (accessLocationIdTemp) {
      callDepartmentAreaSubArea({
        variables: {
          id: accessLocationIdTemp,
        },
      });
    }
  }, [accessLocationIdTemp, callDepartmentAreaSubArea]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  useEffect(() => {
    const filteredPositions: any = AccessLocationBuId
    && allLocationsPositions?.data?.getAllLocationsUserPositions?.businessUnits.map((bu: any) => {
      return bu.userPositions.filter((positionx: any) => {
        return positionx.buId === AccessLocationBuId;
      });
    }).filter((element:any) => {
      if (Object.keys(element).length > 0) {
        return element;
      }
    })[0];
    setPositionsSelectedBu(filteredPositions);
  // eslint-disable-next-line
  }, [AccessLocationBuId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (originalUserInfo?.distributionCenter?.id && !positionsSelectedBu && !AccessLocationBuId)
    && getBuIdByLocationId(originalUserInfo?.distributionCenter?.id);
  // eslint-disable-next-line
  }, [originalUserInfo]);

  const handleAccessLocationChange = (location: any) => {
    const isPositionEdit = originalUserInfo?.position !== editingUserInfo?.position;
    const isFactory = location.type === 'FACTORY';
    toast.info(t(TRANSLATIONS.DONT_FORGET_TO_SET_AN_ACCESS_TO_LOCATION).replace('{location}', location.name));
    const tempObj = {
      general: {
        distributionCenter: {
          id: location.id,
          type: getLocationTypeByLocationId(location.id),
        },
        position: !isFactory
          ? null
          : (!isPositionEdit && isFactory) ? null : editingUserInfo?.position,
        userWorkLocation: {
          id: originalUserInfo?.userWorkLocation?.id,
          workLocation: null,
          subLocation: null,
          subArea: null,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setDisabedFieldsState({ ...disabedFieldsState, area: true, subArea: true });
    setDepartment(null);
    setArea(null);
    setSubArea(null);
    setAccessLocationId(location.id);
    getBuIdByLocationId(location.id);
    setAccessLocationType(getLocationTypeByLocationId(location.id));
  };

  const handlePositionByBUChange = (value: any) => {
    const copyOfEditingUserInfo = JSON.parse(JSON.stringify(editingUserInfo));
    const tempObj = {
      general: {
        ...copyOfEditingUserInfo,
        position: value,
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
  };

  const handleStartWorkingDayChange = (value: any) => {
    const copyOfEditingUserInfo = JSON.parse(JSON.stringify(editingUserInfo));
    const tempObj = {
      general: {
        ...copyOfEditingUserInfo,
        startWorkingDay: value,
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
  };
  const originalOrEditingInfo = !isEditing ? originalUserInfo : editingUserInfo;

  const handleDepartmentChange = (value: any) => {
    const tempObj = {
      general: {
        distributionCenter: {
          id: accessLocationId
          || originalOrEditingInfo?.distributionCenter?.id,
          type: accessLocationType
          || originalOrEditingInfo?.distributionCenter?.type,
        },
        position: positionByBU,
        userWorkLocation: {
          id: originalOrEditingInfo?.userWorkLocation?.id,
          workLocation: value,
          subLocation: null,
          subArea: null,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setDisabedFieldsState({ ...disabedFieldsState, area: false, subArea: true });
    setArea(null);
    setSubArea(null);
    setDepartment(value);
  };

  useEffect(() => {
    const locationid = originalUserInfo?.distributionCenter?.id
    !== editingUserInfo?.distributionCenter?.id
      ? editingUserInfo?.distributionCenter?.id
      : originalUserInfo?.distributionCenter?.id;
    getBuIdByLocationId(locationid);

    const isFactory = editingUserInfo?.distributionCenter?.type === 'FACTORY';

    setShowDepAreaSubarea(isFactory);
  // eslint-disable-next-line
  }, [editingUserInfo, originalUserInfo]);

  const handleAreaChange = (value: any) => {
    const tempObj = {
      general: {
        distributionCenter: {
          id: accessLocationId
          || originalOrEditingInfo?.distributionCenter?.id,
          type: accessLocationType
          || originalOrEditingInfo?.distributionCenter?.type,
        },
        position: positionByBU,
        userWorkLocation: {
          id: originalOrEditingInfo?.userWorkLocation?.id,
          workLocation: department || originalOrEditingInfo?.userWorkLocation?.workLocation,
          subLocation: value,
          subArea: null,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setDisabedFieldsState({ ...disabedFieldsState, subArea: false });
    setSubArea(null);
    setArea(value);
  };

  const handleSubAreaChange = (value: any) => {
    const tempObj = {
      general: {
        distributionCenter: {
          id: accessLocationId
          || originalOrEditingInfo?.distributionCenter?.id,
          type: accessLocationType
          || originalOrEditingInfo?.distributionCenter?.type,
        },
        position: positionByBU,
        userWorkLocation: {
          id: originalOrEditingInfo?.userWorkLocation?.id,
          workLocation: department || originalOrEditingInfo?.userWorkLocation?.workLocation,
          subLocation: area || originalOrEditingInfo?.userWorkLocation?.subLocation,
          subArea: value,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setSubArea(value);
  };
  const handleClickShowPin = () => setShowPin(!showPin);

  // function for Capitalize Character
  const toCamelCase = (str: string) => (str
    ? str
      .toLowerCase()
      .replace(/(^\w|\s\w)(\S*)/g, (_ignore, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    : '');

  const accessLocationOptions:any[] = _.sortBy(allLocationsPositions?.data?.getAllLocationsUserPositions?.locations, ['name']);
  const positionOptions:any[] = _.sortBy(positionsSelectedBu, ['position']);

  const options = useMemo(() => {
    if (departmentAreaSubArea?.getDepartmentAreaSubArea) {
      const { departments } = departmentAreaSubArea?.getDepartmentAreaSubArea;
      const selectedDepartment = departments?.find((localDepartment: any) => {
        return localDepartment.id === (
          department?.id || originalOrEditingInfo
            ?.userWorkLocation?.workLocation?.id
        );
      });

      const areas = selectedDepartment?.areas?.filter((localArea: any) => {
        return localArea?.workLocationId === (
          department?.id || originalOrEditingInfo
            ?.userWorkLocation?.workLocation?.id
        );
      });

      const selectedArea = areas?.find((localArea: any) => {
        return localArea.id === (
          area?.id || originalOrEditingInfo
            ?.userWorkLocation?.subLocation?.id
        );
      });

      const subAreas = selectedArea?.subAreas?.filter((localSubArea: any) => {
        return localSubArea.subAreaId === (
          area?.id || originalOrEditingInfo
            ?.userWorkLocation?.subLocation?.id
        );
      });

      return {
        departments: departments || [],
        areas: areas || [],
        subAreas: subAreas || [],
      };
    }
    return {
      departments: [],
      areas: [],
      subAreas: [],
    };
  // eslint-disable-next-line
  }, [departmentAreaSubArea, area, department]);

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <DistributionCenterInfoCard
          label="BU"
          value={originalUserInfo?.distributionCenter?.bu ?? 'N/A'}
        />
        <DistributionCenterInfoCard label="Zona" img={MazFlag} value="MAZ" />
      </div>
      <div className="py-4">
        <InfoRow
          icon={LocationMarkerIcon}
          label={t(TRANSLATIONS.WORKS_AT)}
          value={
            isEditing ? (
              <Autocomplete
                key="distributionCenter"
                options={accessLocationOptions}
                isOptionEqualToValue={(option, value) => String(option?.id) === String(value?.id)}
                value={accessLocationOptions?.length ? accessLocationOptions
                  ?.find(e => String(e.id) === String(editingUserInfo?.distributionCenter?.id))
                  : () => null}
                getOptionLabel={option => option.name}
                style={{ minWidth: 164 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: 0,
                      },
                    }}
                  />
                )}
                disableClearable
                onChange={(event, value) => {
                  handleAccessLocationChange(value);
                }}
              />
            ) : (
              originalUserInfo?.distributionCenter?.name || 'N/A'
            )
          }
        />
        <InfoRow
          icon={GraduateIcon}
          label={t(TRANSLATIONS.POSITION)}
          value={
            isEditing ? (
              <Autocomplete
                key="position"
                options={positionOptions}
                isOptionEqualToValue={(option, value) => {
                  if (value?.position) {
                    return String(option.position) === String(value.position);
                  }
                  return false;
                }}
                value={editingUserInfo?.position !== null
                  && positionOptions.length > 0 ? positionOptions?.find(e => {
                    return e.position?.toLowerCase()
                    === editingUserInfo?.position?.toLowerCase();
                  }) : () => null}
                getOptionLabel={option => toCamelCase(option.position)}
                style={{ minWidth: 164 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: 0,
                      },
                    }}
                  />
                )}
                disableClearable
                onChange={(event, value) => {
                  handlePositionByBUChange(value.position?.toLowerCase());
                }}
              />
            ) : (
              toCamelCase(originalUserInfo?.position) || 'N/A'
            )
          }
        />

        {showDepAreaSubarea ? (
          <>
            <InfoRow
              icon={homeIcon}
              label={t(TRANSLATIONS.DEPARTMENT)}
              value={
                isEditing ? (
                  <Autocomplete
                    key="department"
                    options={options?.departments}
                    isOptionEqualToValue={(option, value) => {
                      if (value?.id) {
                        return String(option.id) === String(value.id);
                      }
                      return false;
                    }}
                    value={
                      editingUserInfo?.userWorkLocation?.workLocation !== null
                        ? options?.departments?.find((e: any) => {
                          const tmp = editingUserInfo?.userWorkLocation?.workLocation;
                          return String(e.id) === String(tmp?.id);
                        }) || (() => null)
                        : () => null
                    }
                    disabled={disabedFields.department || disabedFieldsState.department}
                    getOptionLabel={option => option.name}
                    style={{ minWidth: 164 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                    disableClearable
                    onChange={(event, value) => {
                      handleDepartmentChange(value);
                    }}
                  />
                ) : (
                  (i18next.language === 'en'
                    ? originalUserInfo?.userWorkLocation?.workLocation?.nameEN
                    : originalUserInfo?.userWorkLocation?.workLocation?.name) || 'N/A'
                )
              }
            />
            {
            (
              userIsAssigner(
                editingUser?.getIn(['permissions', 'roleSlug']),
                editingUser?.getIn(['permissions', 'type', 'type']),
              )
              || userIsExecutor(
                editingUser?.getIn(['permissions', 'roleSlug']),
                editingUser?.getIn(['permissions', 'type', 'type']),
              )
            ) && (
              <InfoRow
                icon={homeIcon}
                label={t(TRANSLATIONS.AREA)}
                value={
                  isEditing ? (
                    <Autocomplete
                      key="area"
                      options={options?.areas}
                      isOptionEqualToValue={(option, value) => {
                        if (value?.id) {
                          return String(option.id) === String(value.id);
                        }
                        return false;
                      }}
                      value={
                        editingUserInfo?.userWorkLocation?.subLocation !== null
                          ? options?.areas?.find((e: any) => {
                            const tmp = editingUserInfo?.userWorkLocation?.subLocation;
                            return String(e.id) === String(tmp?.id);
                          }) || (() => null)
                          : () => null
                      }
                      disabled={disabedFields.area || disabedFieldsState.area}
                      getOptionLabel={option => option.name}
                      style={{ minWidth: 164 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              padding: 0,
                            },
                          }}
                        />
                      )}
                      disableClearable
                      onChange={(event, value) => {
                        handleAreaChange(value);
                      }}
                    />
                  ) : (
                    (i18next.language === 'en'
                      ? originalUserInfo?.userWorkLocation?.subLocation?.nameEN
                      : originalUserInfo?.userWorkLocation?.subLocation?.name) || 'N/A'
                  )
                }
              />
            )
            }
            {
              userIsExecutor(
                editingUser?.getIn(['permissions', 'roleSlug']),
                editingUser?.getIn(['permissions', 'type', 'type']),
              ) && (
                <InfoRow
                  icon={homeIcon}
                  label={t(TRANSLATIONS.SUBAREA)}
                  value={
                isEditing ? (
                  <Autocomplete
                    key="subArea"
                    options={options?.subAreas}
                    isOptionEqualToValue={(option, value) => {
                      if (value?.id) {
                        return String(option.id) === String(value.id);
                      }
                      return false;
                    }}
                    value={
                      editingUserInfo?.userWorkLocation?.subArea !== null
                        ? options?.subAreas?.find((e: any) => {
                          const tmp = editingUserInfo?.userWorkLocation?.subArea;
                          return String(e.id) === String(tmp?.id);
                        }) || (() => null)
                        : () => null
                    }
                    disabled={disabedFields.subArea || disabedFieldsState.subArea}
                    getOptionLabel={option => option.name}
                    style={{ minWidth: 164 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                    disableClearable
                    onChange={(event, value) => {
                      handleSubAreaChange(value);
                    }}
                  />
                ) : (
                  (i18next.language === 'en'
                    ? originalUserInfo?.userWorkLocation?.subArea?.nameEn
                    : originalUserInfo?.userWorkLocation?.subArea?.name) || 'N/A'
                )
              }
                />
              )
            }
          </>
        ) : null}

        <InfoRow
          icon={calendarIcon}
          label={t(TRANSLATIONS.START_WORKING_DAY)}
          value={
                isEditing ? (
                  <div style={{
                    borderWidth: 1,
                  }}
                  >
                    <DatePicker
                      className="date-outlined-input"
                      selected={editingUserInfo?.startWorkingDay
                        ? new Date(editingUserInfo?.startWorkingDay) : new Date(originalUserInfo?.startWorkingDay)}
                      onChange={handleStartWorkingDayChange}
                      dateFormat="yyyy-MM-dd"
                      maxDate={new Date()}
                    />
                  </div>
                ) : (
                  originalUserInfo?.startWorkingDay
                    ? moment.utc(originalUserInfo?.startWorkingDay).locale(i18next.language).format('LL')
                    : 'N/A'
                )
              }
        />
        <Divider light className="my-3" />
        <InfoRow
          icon={UserIcon}
          label={t(TRANSLATIONS.TYPE_OF_COLLABORATOR)}
          value={
            originalUserInfo?.employeeNumber ? t(TRANSLATIONS.INTERNAL) : t(TRANSLATIONS.EXTERNAL)
          }
        />
        {originalUserInfo?.employeeNumber ? (
          <InfoRow
            icon={PinIcon}
            label="Email"
            value={
              originalUserInfo?.email ? (
                <span style={{ textTransform: 'lowercase' }}>{originalUserInfo?.email}</span>
              ) : (
                'Not Available'
              )
            }
          />
        ) : (
          <InfoRow
            icon={PinIcon}
            label="PIN"
            value={
              originalUserInfo?.pin ? (
                <>
                  <InputAdornment position="start">
                    <span role="button" onClick={handleClickShowPin}>
                      {showPin ? <Visibility /> : <VisibilityOff />}
                    </span>
                  </InputAdornment>
                  <input
                    style={{ width: `${originalUserInfo?.pin?.length + 1}ch` }}
                    readOnly
                    type={showPin ? 'text' : 'password'}
                    value={originalUserInfo?.pin}
                  />
                </>
              ) : (
                'N/A'
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default General;
